/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { FaMapMarkedAlt } from "react-icons/fa";
import * as Pages from "../../../utils/Pages";
import WebContext from "../../../utils/WebContext";
import { contentStateData } from "../../../actions/ContentActions";
import GenericDynamicContent from "../../../components/GenericDynamicContent";
import { locationsSearch, changeSessionAttr } from "../../../actions/SessionActions";
import { apiFetchDynamicContent } from "../../../api";

class Home extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { slide: null };
    }
    componentDidMount() {
        this.props.loadUIContext();
        apiFetchDynamicContent( "Page", "home" )
            .then( result => {
                this.setState( { slide: result.dynamicContent.find( tmp => tmp.dynamicContentType === "slider" ) } );
            } );
    }

    render() {
        const page = Pages.getPage( "home" );
        const homeSlides = [];
        if ( this.state.slide ) {
            homeSlides.push( this.state.slide );
        }
        const lang = this.context.language;
        return (
            <div className={ Pages.getPageClassNames( page ) }>
                <Helmet>
                    <title>{ page.seoTitle }</title>
                    <meta name="description" content={ page.seoDescription } />
                    <meta name="keywords" content={ page.seoKeywords } />
                    <meta name="author" content={ page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ page.seoTitle } />
                    <meta name="DC.description" content={ page.seoDescription } />
                    <meta property="og:title" content={ page.seoTitle } />
                    <meta property="og:description" content={ page.seoDescription } />
                    <meta property="og:url" content={ page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ page.seoDescription } />
                    <meta name="twitter:title" content={ page.seoTitle } />
                </Helmet>
                <GenericDynamicContent items={ homeSlides } />
                <div className="dyncontent-colorsection">
                    <Form
                        onSubmit={ ( e ) => {
                            e.preventDefault();
                            this.props.locationsSearch();
                        } }
                        inline
                        className="pw_locations_search_form_home"
                    >
                        <InputGroup>
                            <FormControl type="text" size="md" placeholder={ Pages.text( this.context.language, "navbar.search" ) } onChange={ ( e ) => this.props.changeSessionAttr( "searchText", e.target.value ) } />
                            <Button variant="pw-primary" size="md" onClick={ this.props.locationsSearch }>{ Pages.text( this.context.language, "navbar.search" ) }</Button>
                        </InputGroup>
                    </Form>
                    <br />
                    <Button variant="pw-primary" target="_blank" href="/static/mapa-2023-06.pdf"><FaMapMarkedAlt /> { Pages.text( this.context.language, "customs.downloadmap" ) }</Button>
                </div>
                <GenericDynamicContent items={ [ { type: "locations_featured_items", deckSize: 4 } ] } />
            </div>
        );
    }
}

Home.contextType = WebContext;
Home.serverFetch = contentStateData;
Home.serverFetchType = {
    type: "data",
    module: "content"
};
const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( {}, { locationsSearch, changeSessionAttr } );
export default connect( mapStateToProps, mapDispatchToProps )( Home );
