import { matchPath } from "react-router-dom";
import GenericPage from "../../components/GenericPage";
import GenericNews from "../../components/GenericNews";
import GenericLocations from "../../components/GenericLocations";
import GenericSignup from "../../components/GenericSignup";
import GenericDashboard from "../../components/GenericDashboard";

import Home from "../../components/Home";
import store from "../../store";

// eslint-disable-next-line no-undef
export const ENVIRONMENT = typeof WEBPACK_ENVIRONMENT === "undefined" ? process.env.NODE_ENV : WEBPACK_ENVIRONMENT;

export const PageComponents = {};
PageComponents.PrivacyPolicy = require( "./components/PrivacyPolicy" ).default;
PageComponents.ServiceConditions = require( "./components/ServiceConditions" ).default;
PageComponents.CookiesPolicy = require( "./components/CookiesPolicy" ).default;

export const getUrlBase = ( env ) => {
    if ( env === "development" ) {
        return "http://localhost:2048";
    }
    if ( env === "staging" ) {
        return "http://staging.mallorcagayspot.com";
    }
    return "http://mallorcagayspot.com";
};

export const company = {
    name: "Mallorca Gay Spot",
    legalName: "Mallorca Gay Spot",
    domain: "mallorcagayspot.com",
    lopdFullName: "Mallorca Gay Spot",
    lopdNIF: "",
    lopdEmail: "info@mallorcagayspot.com",
    lopdPostalAddress: "",
    lopdPhone: "",
    facebookPageURL: "",
    instagramPageURL: "",
    whatsappContactURL: "",
    googleFontsTag: "<link href=\"https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700\" rel=\"stylesheet\" />",
    googleAnalyticsTrackingID: null,
    googleTagManagerId: "G-YNRZQGMHN4",
    facebookAPPID: null,
    contactEmail: "info@mallorcagayspot.com",
    managerURLRelative: "/admin-mallorcagayspot",
    googleSignInClientID: null,
    baseURL: getUrlBase( ENVIRONMENT ),
    smartlookCode: null,
    cliengoScriptUrl: "",
    ZohoChatSalesIQCode: null,
    facebookPixelId: null,
    dashboardURLRelative: "/dashboard",
    modSignupEnabled: true,
    shopCart: false,
    modSignupFacebookEnabled: false,
    dashboardModules: [ "welcome", "questions", "locations" ],
    adultsOnlyPage: false,
    footerHideFacebook: true,
    footerDisplaySocialIcons: true,
    footerHideContactButtons: true,
    RGPDCommercialNotificationsCheck: true,
    RGPDSignupCommercialNotificationsCheck: true,
    RGPDStripeInfo: false,
    RGPDAWSInfo: true,
    RGPDMessagesReady: true
};

export const gtag = null;
export const stripe = null;

export const pagantis = {
    enabled: false,
    publicKey: ENVIRONMENT === "development" || ENVIRONMENT === "staging" ? "tk_xxxxx" : "pk_xxxxxx"
};

export const config = {
    reCaptchaSiteKey: "6LeqmKoUAAAAAPBWBD8aQmVM9XIc4qNQgsjGHdFJ"
};

export const NavBar = {
    logoHeight: 72,
    showLocationsCategories: true,
    showLocationsSearch: true,
    hideLocationsSearchInPages: [ "home" ],
    topNavBar: {
        enabled: true,
        sendMessageText: "¿Quieres estar en el mapa?",
        sendMessageTextEn: "Would you like to join us?"
    },
    customMenuItems: []
};

export const all = [
    {
        path: "(/|/en/|/es/)",
        relativeUrl: "/",
        relativeUrlEn: "/en",
        url: "https://mallorcagayspot.com",
        component: Home,
        exact: true,
        id: "home",
        menuText: "Inicio",
        menuTextEn: "Home",
        menuHidden: true,
        title: "Inicio",
        titleEn: "Home",
        subTitle: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        subTitleEn: "Mallorca Gay Spot | Free Palma Map | Mapa de Palma Gratuito",
        breadcrumb: [],
        seoTitle: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoTitleEn: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDescription: "Mallorca Gay Spot es nuestro mapa de comercios y sitios LGBT friendly de Palma de Mallorca y al que todo el mundo puede acceder de forma gratuita tanto en nuestra versión impresa como digital.",
        seoKeywords: "Mallorca Gay Spot es nuestro mapa de comercios y sitios LGBT friendly de Palma de Mallorca y al que todo el mundo puede acceder de forma gratuita tanto en nuestra versión impresa como digital.",
        seoAuthor: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDefaultAlt: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        embeds: [],
        navBarCSSPosition: "fixed",
        gallery: {}
    },
    {
        path: "/politica-privacidad",
        relativeUrl: "/politica-privacidad",
        url: "https://mallorcagayspot.com/politica-privacidad",
        exact: true,
        component: GenericPage,
        childComponentFile: "PrivacyPolicy",
        id: "privacy-policy",
        menuText: "Política de privacidad",
        menuTextEn: "Privacy Policy",
        menuHidden: true,
        title: "Política de privacidad",
        subTitle: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        breadcrumb: [ "home", "privacy-policy" ],
        seoTitle: "Política de privacidad",
        seoDescription: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        seoKeywords: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/condiciones-servicio",
        relativeUrl: "/condiciones-servicio",
        url: "https://mallorcagayspot.com/condiciones-servicio",
        exact: true,
        component: GenericPage,
        childComponentFile: "ServiceConditions",
        id: "terms-conditions",
        menuText: "Términos y Condiciones Generales",
        menuTextEn: "Terms and General Conditions",
        menuHidden: true,
        title: "Términos y Condiciones Generales",
        subTitle: `${ company.legalName } informa a continuación los Términos y Condiciones Generales de los servicios que ofrece desde su sitio web ${ company.domain }`,
        breadcrumb: [ "home", "terms-conditions" ],
        seoTitle: "Condiciones de servicio",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/politica-cookies",
        relativeUrl: "/politica-cookies",
        url: "https://mallorcagayspot.com/politica-cookies",
        exact: true,
        component: GenericPage,
        childComponentFile: "CookiesPolicy",
        id: "cookies-policy",
        menuText: "Política de Cookies",
        menuTextEn: "Cookies Policy",
        menuHidden: true,
        title: "Política de Cookies",
        subTitle: "",
        breadcrumb: [ "home", "cookies-policy" ],
        seoTitle: "",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "(/novedades.*)",
        relativeUrl: "/novedades",
        url: "https://mallorcagayspot.com/novedades",
        exact: true,
        component: GenericNews,
        childComponentFile: "News",
        id: "news",
        menuText: "Novedades",
        menuTextEn: "News",
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Novedades de Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoTitleEn: "Novedades de Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDescription: "Consulta las novedades de Mallorca Gay Spot, eventos, información de interés, y mucho más",
        seoKeywords: "Consulta las novedades de Mallorca Gay Spot, eventos, información de interés, y mucho más",
        seoAuthor: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDefaultAlt: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        embeds: [],
        gallery: {},
        navBarCSSPosition: "fixed",
        navBarPagePadding: 80
    },
    {
        path: "(/dashboard)",
        relativeUrl: "/dashboard",
        url: "https://mallorcagayspot.com/dashboard",
        exact: true,
        component: GenericDashboard,
        id: "dashboard",
        menuText: null,
        menuTextEn: null,
        menuHidden: true,
        title: "Dashboard",
        subTitle: "Datos generales de mi cuenta",
        seoTitle: "Mi cuenta de Mallorca Gay Spot",
        seoDescription: "Acceso a colaboradores que participan en Mallorca Gay Spot",
        seoKeywords: "Acceso a colaboradores que participan en Mallorca Gay Spot",
        breadcrumb: [ "home", "dashboard" ],
        gallery: {},
        seoAuthor: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDefaultAlt: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map"
    },
    {
        path: "(/acceder)",
        relativeUrl: "/acceder",
        url: "https://mallorcagayspot.com/acceder",
        exact: true,
        component: GenericSignup,
        topDynamicContent: null,
        id: "login",
        menuText: "Mi cuenta",
        menuTextEn: null,
        menuHidden: true,
        title: null,
        subTitle: null,
        breadcrumb: [],
        seoTitle: "Crea tu cuenta de Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoTitleEn: "Crea tu cuenta de Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDescription: "Crea tu cuenta de Mallorca Gay Spot",
        seoKeywords: "Crea tu cuenta de Mallorca Gay Spot",
        seoAuthor: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDefaultAlt: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/lugares.*)",
        relativeUrl: "/lugares",
        url: "https://mallorcagayspot.com/lugares",
        exact: true,
        component: GenericLocations,
        id: "locations",
        menuText: "Ver lugares",
        menuHidden: false,
        title: "",
        subTitle: "",
        breadcrumb: [],
        embeds: [],
        gallery: {},
        seoTitle: "Crea tu cuenta de Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoTitleEn: "Crea tu cuenta de Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDescription: "Crea tu cuenta de Mallorca Gay Spot",
        seoKeywords: "Crea tu cuenta de Mallorca Gay Spot",
        seoAuthor: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map",
        seoDefaultAlt: "Mallorca Gay Spot | Mapa de Palma Gratuito | Free Palma Map"
    }
];
const getPagePropByLang = ( page, lang, prop ) => {
    const langProp = `${ prop }${ lang.charAt( 0 ).toUpperCase() + lang.slice( 1 ) }`;
    if ( typeof page[ langProp ] !== "undefined" && page[ langProp ] ) {
        return page[ langProp ];
    }
    return page[ prop ];
};

const applyPageOverwrites = ( page ) => {
    let result = page;
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            result = Object.assign( {}, page, { title: getPagePropByLang( page, lang, "title" ) } );
            Object.keys( page ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( page, lang, key );
            } );
        }
    }
    return result;
};

export const getPage = ( id ) => {
    let result = all.find( ( page ) => page.id === id );
    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

const texts = {
    es: {
        "navbar.title": "",
        "footer.contact.text1": "¿Quieres añadir un sitio al mapa?",
        "footer.contact.text2": "Ponte en contacto con nuestro equipo a través del siguiente formulario. Coméntanos acerca del sitio que te gustaría añadir y te contestaremos a la brevedad.",
        "footer.contact.fullname": "Nombre Completo",
        "footer.contact.fullname.description": "Escribe tu nombre completo para poder dirigirnos a ti correctamente.",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "",
        "footer.contact.phone": "Teléfono",
        "footer.contact.phone.description": "",
        "footer.contact.message": "Mensaje",
        "footer.contact.privacypolicy": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "footer.contact.sendmessage": "Enviar consulta",
        "footer.contact.orremember": "O recuerda que también puede contactarnos a través de",
        "footer.contact.emailbutton": "Correo electrónico",
        "footer.contact.whatsapp": "Enviar un WhatsApp",
        "footer.contact.visitfacebook": "Visítanos en Facebook",
        "footer.contact.successmessage.text1": "Gracias por ponerte en contacto con nosotros.",
        "footer.contact.successmessage.text2": "Hemos recibido tu mensaje y te responderemos a la brevedad a",
        "footer.contact.successmessage.text3": "En {companyName} nos tomamos muy en serio las consultas de nuestros usuarios. Muchas gracias por confiar en nosotros.",
        "footer.copyright.text1": "Copyright Mallorca Gay Spot - Free Palma Map",
        "rgpd.footer.contact.acceptcommercial": "Acepto recibir comunicaciones comerciales",
        "rgpd.generic.validation.acceptcommercial": "Debes aceptar recibir comunicaciones comerciales para poder continuar",
        "navbar.search": "Buscar",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más",
        // customs
        "customs.downloadmap": "Descargar Mapa",
        // mg
        "mg.generic.validation.empty": "Debes completar todos los datos para continuar",
        "mg.generic.validation.invalidemail": "El email ingresado no es válido",
        "mg.customers.status.active": "Cliente",
        "mg.customers.status.inactive": "Desactivado",
        "mg.invoices.status.editing": "Editando",
        "mg.invoices.status.sent": "Enviada",
        "mg.invoices.status.paid": "Pagada",
        "mg.courses.status.editing": "En edición",
        "mg.courses.status.active": "Publicado",
        "mg.courses.status.inactive": "Desactivado",
        "mg.team.status.active": "Activo",
        "mg.team.status.inactive": "Desactivado",
        "mg.generic.validation.giftcard.existingcode": "El código de tarjeta ya ha sido utilizado antes. Modifique el código para continuar.",
        // signup & login
        "signup.form.title": "Completa con tus datos para crear una cuenta",
        "signup.form.subtitle": "",
        "signup.form.facebook.login.error": "Hubo un error en la autenticación a través de Facebook. Por favor, vuelve a intentarlo o completa el formulario de registro.",
        "signup.form.readandacceptpolicy": "He leído y acepto la",
        "signup.form.privacypolicy": "Política de privacidad",
        "signup.form.signupbutton": "Crear mi cuenta",
        "signup.form.backloginbutton": "Acceder a mi cuenta",
        "signup.form.separator.text": "¿Ya tienes cuenta?",
        "signup.validation.emailalreadyexists": "Ya existe una cuenta vinculada al email ingresado. Por favor, accede a tu cuenta o recupera tu clave.",
        "signup.validation.passwordstrength": "La clave debe contener al menos 6 caracteres e incluir al menos una letra y un número. Se recomienda usar símbolos y mayúsculas para mayor seguridad.",
        "signup.password.hinttext": "Mínimo 6 caracteres, con al menos una letra y un número",
        "signup.form.facebookbutton": "Continuar con Facebook",
        "signup.validation.facebook.sessionexpired": "Se ha producido un error al conectarse con Facebook, por favor, vuelva a intentarlo.",
        "signup.success.title": "Accede a tu email para confirmar tu cuenta",
        "signup.success.text": "Te hemos enviado un correo a {email} para validar tu dirección de email. Revisa tu correo y verifica tu cuenta para poder continuar.",
        "signup.login.email.notverified": "Tienes pendiente la verificación de tu cuenta de correo. Por favor, accede a tu correo y continúa la verificación de tu cuenta. Muchas gracias",
        "signup.login.invalid.credentials": "El usuario y clave ingresados son incorrectos",
        "login.form.title": "Accede a tu cuenta",
        "login.form.subtitle": "",
        "login.form.loginbutton": "Acceder a mi cuenta",
        "login.form.separator.text": "¿No tienes cuenta?",
        "login.form.signupbutton": "Crear una cuenta",
        "checkout.form.selectplan": "¿Plan Anual o Mensual?",
        "checkout.form.selectplan.text": "Selecciona si prefieres pagar mensual o anualmente (Ahorro anual de 50 EUR)",
        "checkout.form.selectplan.selector": "Seleccione Plan Mensual o Anual...",
        "checkout.validation.useralreadysubscribed": "Ya te encuentras dado de alta en este Plan.",
        "checkout.form.title": "",
        "generic.validation.close": "cerrar",
        "generic.validation.empty": "Complete todos los datos del formulario para poder continuar. Muchas gracias.",
        "generic.validation.recaptcha": "Marque la casilla \"No soy un robot\" para poder continuar.",
        "generic.validation.privacypolicy": "Debe aceptar la Política de Privacidad para continuar.",
        "generic.validation.invalidemail": "Por favor compruebe que su dirección de email es correcta. Al parecer no tiene un formato válido.",
        "generic.validation.passwordmismatch": "La confirmación de la clave no coincide. Compruebe que las claves coinciden exactamente.",
        "generic.server.error": "Hubo un error en la conexión con el servidor.",
        "signup.emailverified.success": "¡Enhorabuena! ¡Ya puedes utilizar tu cuenta!",
        "signup.navbar.login": "Acceder",
        "signup.navbar.signup": "Crear cuenta",
        "signup.navbar.dashboard": "Mi cuenta",
        "signup.navbar.logout": "Cerrar",
        "checkout.currentproduct.content.title": "Datos de mi plan actual",
        "checkout.currentproduct.content.subtitle": "¡Enhorabuena! Actualmente te encuentras suscrito a nuestro plan",
        "checkout.currentproduct.yourplan": "Tu plan actual",
        "checkout.currentproduct.yourplan.text": "Actualmente te encuentras suscrito al siguiente plan:",
        "signup.form.signuppassword.title": "Ingresa una clave",
        "footer.floatingvideo.sendmessage": "Déjame un mensaje",
        "signup.form.passwordconfirmation": "Confirmar Clave",
        "signup.form.password": "Clave",
        "signup.resetpassword.title": "Crea una nueva clave de acceso",
        "signup.resetpassword.subtitle": "Escribe tu nueva clave de acceso",
        "signup.form.newpasswordconfirmation": "Confirmar nueva clave",
        "signup.form.newpassword": "Nueva clave",
        "signup.form.newpassword.save": "Guardar cambios",
        "signup.forgotpassword.title": "¿Has olvidado tu contraseña?",
        "signup.forgotpassword.subtitle": "Escribe el email de tu cuenta para recuperarla",
        "signup.forgotpassword.emailnotexists": "El email que has ingresado no pertenece a una cuenta de usuario.",
        "signup.forgotpassword.pleasecheckemail": "Por favor, revisa tu correo electrónico. Te hemos enviado un enlace con el que podrás crear una nueva clave de acceso.",
        "signup.forgotpassword.processbutton": "Recuperar clave",
        "signup.login.newpasswordsaved": "Tu nueva clave ha sido guardada. Ingresa tu email para acceder.",
        "signup.form.forgotpasswordlink": "¿Has olvidado tu contraseña?",
        "signup.emailverified.textclicktocontinue": "Haz clic en el siguiente enlace para continuar",
        "signup.emailverified.continue": "Continuar",
        // shop
        // locations
        "locations.search.noresults": "No se han encontrado resultados para",
        "dashboard.locations.title": "Tus lugares en el mapa",
        "dashboard.locations.text.nolocations": "Por el momento no tienes lugares vinculados a tu cuenta. En caso de que aún no nos hayas enviado los datos de tu comercio, servicio o lugar, nos pondremos en contacto contigo vía email a la brevedad. Muchas gracias",
        "dashboard.locations.text": "Tienes vinculados los siguientes lugares del mapa:",
        // dashboard
        "dashboard.bienvenida.title": "Bienvenid@ a tu Dashboard",
        "dashboard.bienvenida.text": "Desde tu Dashboard podrás acceder de forma directa a la información de tus lugares vinculados y solicitar modificaciones, baja o actualizar las fotos.",
        "dashboard.questions.title": "¿Preguntas?",
        "dashboard.questions.text": "No dudes en enviarnos un mensaje y te responderemos a la brevedad.",
        "dashboard.myplan.title": "Mi Plan Actual",
        "dashboard.myplan.text": "",
        "dashboard.myplan.text.noproducts": "Actualmente no tienes contratado ningún plan.",
        "dashboard.myplan.bajalink": "Solicitar baja"
    },
    en: {
        "navbar.title": "",
        "footer.contact.text1": "Would you like to be in the map?",
        "footer.contact.text2": "Please do not hesitate to contact us, tell us about your business We will reply as soon as possible.",
        "footer.contact.fullname": "Full name",
        "footer.contact.fullname.description": "Please enter your full name",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "",
        "footer.contact.phone": "Phone",
        "footer.contact.phone.description": "",
        "footer.contact.message": "Message",
        "footer.contact.privacypolicy": `I have read and accept the <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and the <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "footer.contact.sendmessage": "Send message",
        "footer.contact.orremember": "Or remember you can also send us a message via",
        "footer.contact.emailbutton": "Email",
        "footer.contact.whatsapp": "Send WhatsApp",
        "footer.contact.visitfacebook": "Visit us on Facebook",
        "footer.contact.successmessage.text1": "Thank you to contact us",
        "footer.contact.successmessage.text2": "Your message has been successfully sent and we´ll reply you as soon as possible.",
        "footer.contact.successmessage.text3": "At {companyName} we work hard to reply our customers messages in a professional way. Thank you for your patience. ",
        "footer.copyright.text1": "Copyright Mallorca Gay Spot - Mapa de Mallorca",
        "navbar.search": "Search",
        // news
        "news.categories": "Categories",
        "news.viewmore": "View more",
        // customs
        "customs.downloadmap": "Download Map",
        // signup & login
        "signup.form.title": "Complete the data to signup",
        "signup.form.subtitle": "",
        "signup.form.facebook.login.error": "Hubo un error en la autenticación a través de Facebook. Por favor, vuelve a intentarlo o completa el formulario de registro.",
        "signup.form.readandacceptpolicy": "I've read and accept",
        "signup.form.privacypolicy": "Privacy Policy",
        "signup.form.signupbutton": "Create account",
        "signup.form.backloginbutton": "Access my account",
        "signup.form.separator.text": "¿Already have an account?",
        "signup.validation.emailalreadyexists": "The email is already in use. Forgot your password?",
        "signup.validation.passwordstrength": "The password is too short and must contain letters and numbers.",
        "signup.password.hinttext": "Min 6 chars, using letters and numbers",
        "signup.form.facebookbutton": "Continue with Facebook",
        "signup.validation.facebook.sessionexpired": "Se ha producido un error al conectarse con Facebook, por favor, vuelva a intentarlo.",
        "signup.success.title": "Please verify your account from your inbox",
        "signup.success.text": "We've sent you an email to {email} in order to verify your account. Please open your inbox and continue the verification process before continue.",
        "signup.login.email.notverified": "Please verify your account. Access your inbox and continue the verification process.",
        "signup.login.invalid.credentials": "Invalid email and password",
        "login.form.title": "Access your account",
        "login.form.subtitle": "",
        "login.form.loginbutton": "Access my account",
        "login.form.separator.text": "¿Need an account?",
        "login.form.signupbutton": "Create an account",
        "checkout.form.selectplan": "¿Plan Anual o Mensual?",
        "checkout.form.selectplan.text": "Selecciona si prefieres pagar mensual o anualmente (Ahorro anual de 50 EUR)",
        "checkout.form.selectplan.selector": "Seleccione Plan Mensual o Anual...",
        "checkout.validation.useralreadysubscribed": "Ya te encuentras dado de alta en este Plan.",
        "checkout.form.title": "",
        "generic.validation.close": "close",
        "generic.validation.empty": "Please complete all the data to continue.",
        "generic.validation.recaptcha": "Check the \"Anti-bot\" challenge to continue.",
        "generic.validation.privacypolicy": "You must accept Privacy Policy before continue.",
        "generic.validation.invalidemail": "Invalid email",
        "generic.validation.passwordmismatch": "Password confirmation doesn't match.",
        "generic.server.error": "Connection error while sending the data to the server.",
        "signup.emailverified.success": "¡Your acount is ready!",
        "signup.navbar.login": "Access",
        "signup.navbar.signup": "Create account",
        "signup.navbar.dashboard": "My account",
        "signup.navbar.logout": "Close",
        "checkout.currentproduct.content.title": "Datos de mi plan actual",
        "checkout.currentproduct.content.subtitle": "¡Enhorabuena! Actualmente te encuentras suscrito a nuestro plan",
        "checkout.currentproduct.yourplan": "Tu plan actual",
        "checkout.currentproduct.yourplan.text": "Actualmente te encuentras suscrito al siguiente plan:",
        "signup.form.signuppassword.title": "Enter password",
        "footer.floatingvideo.sendmessage": "Send message",
        "signup.form.passwordconfirmation": "Confirm Password",
        "signup.form.password": "Password",
        "signup.resetpassword.title": "Reset your password",
        "signup.resetpassword.subtitle": "Enter your new password",
        "signup.form.newpasswordconfirmation": "Confirm your new password",
        "signup.form.newpassword": "New password",
        "signup.form.newpassword.save": "Save changes",
        "signup.forgotpassword.title": "Forgot your password?",
        "signup.forgotpassword.subtitle": "Enter your email account to reset your password",
        "signup.forgotpassword.emailnotexists": "Invalid email.",
        "signup.forgotpassword.pleasecheckemail": "Please check your email. We've sent you a link to reset your password.",
        "signup.forgotpassword.processbutton": "Reset Password",
        "signup.login.newpasswordsaved": "Your new password has been saved. Enter your email to access your account.",
        "signup.form.forgotpasswordlink": "Forgot your password?",
        "signup.emailverified.textclicktocontinue": "Click the following link to continue",
        "signup.emailverified.continue": "Continue",
        // shop
        // locations
        "shop.search.noresults": "No results found for"
    }
};

export const defaultLanguage = "es";
export const availableLanguages = [ "es", "en" ];

// GENERIC
export const getPageByPath = ( path ) => {
    let result = all.find( ( page ) => {
        if ( page.path === path ) {
            return page;
        }
        if ( matchPath( path, page ) ) {
            return page;
        }
        return null;
    } );

    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

export const manager = {};
manager.getRelativeUrl = ( relative ) => `${ company.managerURLRelative }/${ relative }`;

export const text = ( lang, id, replacements ) => {
    let result = "";
    if ( texts[ lang ] && texts[ lang ][ id ] ) {
        result = texts[ lang ][ id ];
    } else if ( texts.es && texts.es[ id ] ) {
        result = texts.es[ id ];
    }
    if ( typeof replacements !== "undefined" ) {
        Object.keys( replacements ).forEach( key => {
            result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
        } );
    }
    return result;
};

export const getLanguageName = ( lang ) => {
    switch ( lang ) {
        case "en":
            return "English";
        case "es":
            return "Español";
        default:
            return "Español";
    }
};

export const getLangByUrlPath = ( path ) => {
    let result = defaultLanguage;
    availableLanguages.forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

export const applyShopOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const applyNewsOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const contactForms = null;
// Example:
// export const floatingVideos = [
//     {
//         relativeUrl: "/",
//         exactUrl: true,
//         src: "https://www.youtube.com/embed/5MTYDYkGxP4",
//         provider: "youtube",
//         sendMessage: true,
//         displayScrollY: 0,
//         disableAutoplay: true
//     }
// ];
export const floatingVideos = null;
export const emailTemplates = {
    theme: {
        logoBackgroundColor: "white",
        logoUrl: `${ company.baseURL }/static/logo-rectangle.png`,
        thanksBackgroundColor: "#F8F8F8",
        buttonBackgroundColor: "#fbc965",
        thanksTitleColor: "#333333",
        buttonTextColor: "black",
        purchaseDetailsUrl: `${ company.baseURL }/shop/purchase`,
        successBackgroundColor: "#333333",
        successTextColor: "#333333",
        defaultFont: "Arial",
        defaultFontColor: "#333333",
        purchaseThanksTitle: "Muchas gracias por su compra",
        purchaseThanksSubTitle: "Por favor, conserve la siguiente información.",
        footerText: `Para cualquier información no dude en contactarnos a través de nuestra web ${ company.baseURL }`
    }
};

export const floatingActionButtons = null;
