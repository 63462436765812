import * as Pages from "../domain/mallorcagayspot.com/Pages";

export * from "../domain/mallorcagayspot.com/Pages";

export const getPageClassNames = ( page ) => {
    let classNames = "";
    if ( ( !Pages.NavBar.type || Pages.NavBar.type === "classic" ) && page.navBarCSSPosition === "fixed" ) {
        classNames = "pw_navbar_fixed_on";
        if ( Pages.NavBar.topNavBar.enabled ) {
            classNames = "pw_navbar_fixed_topnavbar_on";
        }
    }
    return classNames;
};

export const dynamicText = ( lang, text ) => {
    let jsonText = null;
    if ( text && text.trim().startsWith( "{" ) ) {
        try {
            jsonText = JSON.parse( text.trim() );
        } catch ( error ) {
            console.log( error );
            jsonText = null;
        }
    }
    if ( jsonText ) {
        if ( jsonText[ lang ] ) {
            return jsonText[ lang ];
        } else if ( jsonText.es ) {
            return jsonText.es;
        } else if ( jsonText.en ) {
            return jsonText.en;
        }
    }
    return text;
};
