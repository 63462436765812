/* eslint-disable quotes */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
/* eslint-disable complexity */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Container, Button, Ratio } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GenericDynamicContent from "../components/GenericDynamicContent";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import { locationsStateData } from "../actions/LocationsActions";
import { contentStateData } from "../actions/ContentActions";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import WebContext from "../utils/WebContext";

class Locations extends React.Component {
    constructor( props ) {
        super( props );
        this.renderCategory = this.renderCategory.bind( this );
        this.renderCategoryItems = this.renderCategoryItems.bind( this );
        this.renderPaginatedCategoryItems = this.renderPaginatedCategoryItems.bind( this );
        this.handleScroll = this.handleScroll.bind( this );
        this.getCategory = this.getCategory.bind( this );
        this.getCategoryName = this.getCategoryName.bind( this );
        this.page = Pages.getPage( "locations" );
        const selectedCategory = props.locations.categories.find( ( category ) => props.location.pathname === `${ this.page.relativeUrl }/${ category.locationCategoryCategoryId }` );
        this.scrollTotalItemsIncrement = 50;
        const defaultCategoryId = props.locations.categories.length > 0 ? props.locations.categories[ 0 ].locationCategoryCategoryId : null;
        this.state = {
            categoryId: selectedCategory && selectedCategory.locationCategoryCategoryId ? selectedCategory.locationCategoryCategoryId : defaultCategoryId,
            selectedItem: null,
            onScrollTotalItems: 50,
            showCategoryViewMoreLink: true
        };
        this.renderArticle = this.renderArticle.bind( this );
        this.items = props.locations.items;
        this.state.selectedItem = this.items.find( ( item ) => `${ this.page.relativeUrl }/${ item.locationId }` === props.location.pathname );
        const tmpParams = new URLSearchParams( props.location.search );
        const encodedSearchText = tmpParams.get( "q" );
        this.searchText = "";
        if ( encodedSearchText ) {
            this.searchText = DataUtils.base64Decode( encodedSearchText );
            this.state.categoryId = null;
        }
        this.latestMaxItems = 50;
    }
    componentDidMount() {
        this.props.loadUIContext();
        window.addEventListener( "scroll", this.handleScroll );
    }
    componentWillUnmount() {
        window.removeEventListener( "scroll", this.handleScroll );
    }
    getCategoryName( categoryId ) {
        let category = this.props.locations.categories.filter( ( item ) => categoryId === item.locationCategoryCategoryId )[ 0 ];
        category = Pages.applyShopOverides( category );
        return category.locationCategoryName;
    }
    getCategory( categoryId ) {
        let category = this.props.locations.categories.filter( ( item ) => categoryId === item.locationCategoryCategoryId )[ 0 ];
        category = Pages.applyShopOverides( category );
        return category;
    }
    static getCategoryUrl( baseurl, categoryId ) {
        return `${ baseurl }/${ categoryId }`;
    }
    handleScroll() {
        if ( window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight ) {
            this.setState( { onScrollTotalItems: this.state.onScrollTotalItems + this.scrollTotalItemsIncrement } );
        }
    }
    renderCategory() {
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        if ( this.state.selectedItem ) {
            return null;
        }
        const category = this.state.categoryId ? this.getCategory( this.state.categoryId ) : null;
        return (
            <div className="locations_category_container">
                <div className="locations_category_items">
                    { this.renderPaginatedCategoryItems( this.renderCategoryItems() ) }
                    { this.state.showCategoryViewMoreLink ? <Button variant="link" block onClick={ () => this.setState( { onScrollTotalItems: this.state.onScrollTotalItems + this.scrollTotalItemsIncrement } ) }>{ Pages.text( this.context.language, "shop.items.loadmore" ) } ...</Button> : null }
                </div>
                { category ?
                    <Helmet>
                        { category.locationCategoryName ? <title>{ category.locationCategoryName }</title> : null }
                        { category.locationCategoryName ? <meta property="og:title" content={ category.locationCategoryName } /> : null }
                        { category.locationCategoryName ? <meta name="DC.title" content={ category.locationCategoryName } /> : null }
                        { category.locationCategoryName ? <meta name="twitter:title" content={ category.locationCategoryName } /> : null }
                    </Helmet> : null }
            </div>
        );
    }
    renderPaginatedCategoryItems( argItems ) {
        const items = argItems;
        const cols = 3;
        const result = [];
        let colsItems = [];
        if ( this.searchText && items.length === 0 ) {
            return <div style={ { margin: 20, textAlign: "center" } }><h4>{ Pages.text( this.context.language, "locations.search.noresults" ) }: <b>{ this.searchText }</b></h4></div>;
        }
        for ( let i = 0; i < items.length; i += 1 ) {
            const colNumber = i + 1;
            colsItems.push( items[ i ] );
            if ( colNumber % cols === 0 || colNumber === items.length ) {
                // filling left cols at the end
                const modCols = colNumber % cols;
                if ( modCols > 0 ) {
                    for ( let c = 0; c < cols - modCols; c += 1 ) {
                        colsItems.push( [] );
                    }
                }
                result.push( <Row key={ `col-location-${ i }` }> { colsItems.map( ( tmp, ci ) => <Col md={ 4 } xs={ 12 } key={ `col-location-${ i }-${ ci }` }> { tmp }</Col> ) } </Row> );
                colsItems = [];
            }
        }
        return result;
    }
    renderCategoryItems() {
        let isLandscape = true;
        let maxPerCategory = 6;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        if ( this.props.UIContext.loaded ) {
            maxPerCategory = this.state.onScrollTotalItems;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        if ( this.state.selectedItem ) {
            return null;
        }
        const categoryAndSubCategoriesIds = this.state.categoryId ? [ this.state.categoryId ] : [];
        const items = this.items.filter( ( item ) => {
            if ( this.searchText ) {
                const pattern = new RegExp( `.*${ this.searchText }.*`, "i" );
                if ( item.locationTitle && item.locationTitle.match( pattern ) ) {
                    return true;
                }
                if ( ( item.locationShortText && item.locationShortText.match( pattern ) ) || ( item.locationShortTextEn && item.locationShortTextEn.match( pattern ) ) ) {
                    return true;
                }
                if ( item.locationTags && item.locationTags.match( pattern ) ) {
                    return true;
                }
                let categoryName = this.getCategoryName( item.locationCategoryId );
                if ( categoryName && categoryName.match( pattern ) ) {
                    return true;
                }
                return false;
            }
            return categoryAndSubCategoriesIds.includes( item.locationCategoryId ) || this.state.categoryId === "latest";
        } );
        if ( this.state.showCategoryViewMoreLink && ( this.state.categoryId === "latest" || items.length <= maxPerCategory ) ) {
            this.setState( { showCategoryViewMoreLink: false } );
        }
        return items.slice( 0, maxPerCategory ).map( ( argitem, index ) => {
            if ( this.state.categoryId === "latest" && index >= this.latestMaxItems ) {
                return null;
            }
            return <GenericDynamicContent items={ [ { type: "location_card", value: argitem } ] } />;
        } );
    }
    renderArticle() {
        let item = this.state.selectedItem;
        if ( !item ) {
            return null;
        }
        item = Pages.applyShopOverides( item );
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const coverDimensions = { width: 0, height: 0 };
        if ( this.props.UIContext.loaded ) {
            coverDimensions.width = this.props.UIContext.windowWidth - 30;
            if ( isLandscape ) {
                coverDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            coverDimensions.height = ( coverDimensions.width / 16 ) * 9;
        }
        return (
            <div className="pw_location_article">
                <Row>
                    <Col xs="12" md="3">
                        <GenericDynamicContent items={ [ { type: "location_card", value: item } ] } />
                    </Col>
                    <Col xs="12" md="9">
                        { this.props.UIContext.loaded ?
                            <Ratio aspectRatio="16x9"><div dangerouslySetInnerHTML={ { __html: item.locationEmbedMap } } /></Ratio>
                            : null
                        }
                    </Col>
                </Row>
                <Helmet>
                    { item.locationTitle ? <title>{ item.seoTitle ? item.locationTitle : item.locationTitle }</title> : null }
                    { item.locationTitle ? <meta property="og:title" content={ item.locationTitle } /> : null }
                    { item.locationTitle ? <meta name="DC.title" content={ item.locationTitle } /> : null }
                    { item.locationTitle ? <meta name="twitter:title" content={ item.locationTitle } /> : null }
                    { item.locationShortText ? <meta name="description" content={ item.locationShortText } /> : null }
                    { item.locationShortText ? <meta name="keywords" content={ item.locationShortText } /> : null }
                    { item.locationShortText ? <meta property="og:description" content={ item.locationShortText } /> : null }
                    { item.locationImage ?
                        <meta property="og:image" content={ DataUtils.getPublicImageUrl( item.locationImage ) } />
                        :
                        <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    }
                </Helmet>
            </div>
        );
    }
    render() {
        const lang = this.context.language;
        return (
            <div id="locations" className={ Pages.getPageClassNames( this.page ) }>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    { Pages.company.SEOShopNoIndex ? <meta name="robots" content="noindex, nofollow, noimageindex" /> : <meta name="robots" content="all" /> }
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ this.page.seoDescription } />
                    <meta name="twitter:title" content={ this.page.seoTitle } />
                </Helmet>
                <Container className="pw_locations">
                    { this.renderCategory() }
                    { this.renderArticle() }
                </Container>
            </div>
        );
    }
}

Locations.contextType = WebContext;
Locations.serverFetch = null;
Locations.serverFetchType = {
    type: "data",
    modules: [
        { module: "locations", serverFetch: locationsStateData },
        { module: "content", serverFetch: contentStateData }
    ]
};

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    locations: state.locations
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Locations ) );
